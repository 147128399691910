"use strict";

import axios from "axios";
import user from '@/assets/script/cache'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // 需要创建/.env文件并指定VUE_APP_API_URL，同时在/plugins/axios.js配置 axios.create({baseURL: process.env.VUE_APP_API_URL})
  baseURL: process.env.VUE_APP_API_URL || "",
  // timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    let token = user.get('token')
    token && (config.headers['token'] = token)
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
