export default {
  login: 'index/handleLogin',
  home: 'index/getIndex',
  articleList: 'index/articleList',
  articleId: 'index/articleDetail',
  articleZan: 'index/articleGood', // 点赞

  teamList: 'index/teamList',
  teamJoin: 'index/joinTeam',

  userSet: 'index/updateUserInfo',
  userId: 'index/getUserInfo',

  ltsTop: 'index/rankedGame', // 擂台赛相关
  ltsTopFinish: 'index/doRankedGame',
  ltsPK: 'index/pkGame',
  ltsPKFinish: 'index/doPkGame',

  wcyBattle: 'index/getGuess', // 我参与 我来战
  wcyBattleNext: 'index/doGuess',
  wlpList: 'index/getDubbingList', //我参与 我来配
  wlpId: 'index/getDubbingInfo',
  wlpAudio: 'index/subDubbing',
  wlzInfo: 'index/getPlant', // 我参与 我来种
  wlzGetQuestion: 'index/getPlantQuestion',
  wlzCommit: 'index/subPlant',

  meTopTeams: 'index/allTeamRanking', // 我的 相关
  meTopTeam: 'index/allUserRanking',
  meTopPartner: 'index/battleRanking',
  meCredit: 'index/getCreditLog',
  meAbout: 'index/getAbout',
}
