<template lang="pug">
div#app-bmj
  div(ref="bmj")
    div.bg.red.padding._5_10 传承红色保密基因 争当新时代“守密人”
    div#navs(:class="{hide: !navs.show}"): ul
      li.small.er.center.txt.red.padding._10_0(v-for="(n, i) in navs.items" :class="{active: i == navs.i}")
        router-link(:to="n.url")
          img(:src="require(`@/assets/ico/${i == navs.i?'_':''}${n.ico}.png`)")/
          p {{n.title}}
          span.small {{n.tip}}
  router-view
  tipBox.toask(bgcolor="rgba(0,0,0,.7)" height="4rem" width="8rem" :show="tip.show")
    div.flex.txt.white: p.grow.center {{tip.msg}}
</template>
<script>
import user from '@/assets/script/cache'
import model from '@/components/modal/index';
export default {
  components: {tipBox: model},
  data(){return {
    navs: {
      i: 0,
      show: true,
      items: [
        {title: '首页', ico: 'home', url: '/home'},
        {title: '随身听', ico: 'audio', tip: '红色保密故事', url: '/suishenting'},
        {title: '连连看', ico: 'share', tip: '保密工作常识', url: '/lianliankan'},
        {title: '擂台赛', ico: 'exam', tip: '保密知识技能', url: '/leitaisai'},
        {title: '我参与', ico: 'join', tip: '保密创意活动', url: '/wocanyu'},
        {title: '我的', ico: 'me', url: '/me'},
      ]
    },
    tip: this.$store.state.tip,
  }},
  watch:{
    $route(to){
      // 更新active项
      let i = this.navs.items.findIndex(n=> to.path.indexOf(n.url) > -1)
      i > -1 && (this.navs.i = i)
      // 文章页面没有导航条
      this.navs.show = !(/(suishenting|lianliankan)\/\d+/.test(to.path))
      // 必须加入团队 才能答题
      if(['leitaisaiTop', 'leitaisaiPk', 'wolaizhan', 'wolaipeiId', 'wolaipeiList', 'wolaizhong'].includes(to.name)){
        let team_id = user.get('team_id') / 1
        team_id || this.$router.push({path: '/me', query: {box: 'team'}})
      }
      this.$store.commit('tipHide')
    }
  },
  mounted(){
    this.$req.login((res)=>{
      user.save(res)
      let hs = {topbar: this.$refs.bmj.offsetHeight, win: window.innerHeight}
      this.$store.commit('setHeights', hs)
    })
  }
}
</script>

<style lang="less">
@import "~@/assets/less/base.less";
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
#navs{
  &.hide{display: none; }
  ul{
    display: flex;
    .__border(bottom, #ccc);
    li{
      width: calc(100% / 6);
      .__border(bottom, transparent, solid, 2);
      .__rem(-2, margin-bottom);
      &.active{
        border-color: var(--red);
      }
    }
    img{
      .__rem(24, width);
      height: $width;
    }
    span{
      display: block; zoom: 0.8;
      .__rem(2);
    }
  }
}
.toask.modal-box{
  .__rem(6, border-radius);
  .flex{
    height: 4rem;
    .__rem(14);
  }
}
</style>
