import axios from '@/plugins/axios'
import urls from '@/assets/script/url.conf'
const Req = {
  uploader(uri, data, success){
    let config = {method: 'post', url: urls[uri]}
    config.data = data
    config.headers = {'Content-Type': 'multipart/form-data'}
    axios.request(config).then(res=>{
      success && success(res)
    })
  },
  /**
   * 发起请求
   * @param {String} uri 注意配置axios的baseURL
   * @param {Array} data 
   * @param {Function} success 
   * @param {Function} fail 
   * @param {String} method
   * @return primise 
   */
  request(url, data, success, fail, method = 'post'){
    if(typeof data == 'function'){// 如果没有data，则默认是get请求
      [success, data, method] = [data, {}, 'get']
    }
    let config = {method, url, data}
    if(method == 'post'){ //using application/x-www-form-urlencoded format
      config.data = new URLSearchParams(data)
    }
    axios.request(config).then(res=>{
      let {code, msg, data} = res.data
      switch(code){
        case 0:
          success && success(data)
        break; case 1:
          fail ? fail(res.data) : console.error('请求失败，检查参数')
        break; case 2:
          // 没有登录
        break;
      }
    }).catch(res=>{
      console.error(res)
    })
  },
}
Object.keys(urls).forEach((k)=>{
  Req[k] = (data, success, fail) => Req.request(urls[k], data, success, fail)
})
export default Req