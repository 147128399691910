import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {user: 'Joe'},
    tip: {target: {}, msg: '', show: false, duration: 1500}, // 提示框
    heights: {topbar: 0, win: 0}, // 用于下拉刷新的相关高度值
  },
  mutations: {
    setUser(state, user){
      console.log(state, user)
    },
    setHeights(state, hs = {}){
      Object.keys(hs).forEach((k)=>{
        if(k in state.heights){
          state.heights[k] = hs[k]
        }
      })
    },
    tipShow(state, msg){
      if(typeof msg == 'object'){
        Object.keys(msg).forEach((k)=>{
          if(k in state.tip){
            state.tip[k] = msg[k]
          }
        })
      }else{
        state.tip.msg = msg
      }
      state.tip.show = true
      setTimeout(()=>{
        state.tip.show = false
      }, state.tip.duration)
    },
    tipHide(state){
      state.tip.show = false
    },
  },
})
