class Cache {
  sessionStorageKey
  constructor(sessionStorageKey = 'user') {
    this.sessionStorageKey = sessionStorageKey
  }
  get(key, sessionStorageKey){
    let sess = sessionStorage.getItem(sessionStorageKey || this.sessionStorageKey)
    sess = JSON.parse(sess)
    return sess?.[key] ?? sess
  }
  set(key, val, sessionStorageKey){
    let sess = this.get('', sessionStorageKey)
    sess[key] = val
    sessionStorage.setItem(sessionStorageKey || this.sessionStorageKey, JSON.stringify(sess))
    return this
  }
  save(obj, sessionStorageKey){
    typeof obj  == 'string' || (obj = JSON.stringify(obj))
    sessionStorage.setItem(sessionStorageKey || this.sessionStorageKey, obj)
  }
  clear(sessionStorageKey){
    sessionStorage.removeItem(sessionStorageKey || this.sessionStorageKey)
  }
}
export default new Cache