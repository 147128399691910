import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    alias: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/suishenting',
    component: () => import('@/views/suishenting/index.vue')
  },
  {
    path: '/suishenting/:id(\\d+)',
    component: () => import('@/views/suishenting/id.vue')
  },
  {
    path: '/lianliankan',
    component: () => import('@/views/lianliankan/index.vue')
  },
  {
    path: '/lianliankan/:id(\\d+)',
    component: () => import('@/views/lianliankan/id.vue')
  },
  {
    path: '/leitaisai',
    redirect: { name: 'leitaisaiTop' },
    component: () => import('@/views/leitaisai/index.vue'),
    children: [
      {
        path: '/leitaisai/top',
        name: 'leitaisaiTop',
        component: () => import('@/views/leitaisai/top.vue')
      },
      {
        path: '/leitaisai/pk',
        name: 'leitaisaiPk',
        component: () => import('@/views/leitaisai/pk.vue')
      },
    ],
  },
  {
    path: '/wocanyu',
    redirect: { name: 'wolaizhan' },
    component: () => import('@/views/wocanyu/index.vue'),
    children: [
      {
        path: '/wocanyu/wolaizhan',
        name: 'wolaizhan',
        component: () => import('@/views/wocanyu/wolaizhan.vue')
      },
      {
        path: '/wocanyu/wolaipei',
        name: 'wolaipeiList',
        component: () => import('@/views/wocanyu/wolaipei.vue')
      },
      {
        path: '/wocanyu/wolaipei/:id(\\d+)',
        name: 'wolaipeiId',
        component: () => import('@/views/wocanyu/wolaipeiId.vue')
      },
      {
        path: '/wocanyu/wolaizhong',
        name: 'wolaizhong',
        component: () => import('@/views/wocanyu/wolaizhong.vue')
      },
    ],
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('@/views/me/index.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: "/"
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
