<template lang="pug">
div.modal-box.fixed(:style="`background-color:${bgcolor};height:${height};top:calc(50% - ${height} / 2);width:${width};left:calc(50% - ${width} / 2)`" :class="{hide: !show}")
  div.ico-close(@click="_close" v-if="closeIco")
    svg(viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg")
     path(d="M97.018 1023.854l-97.018-97.018 926.837-926.837 97.018 97.018-926.837 926.837z" fill="#ffffff")/
     path(d="M0.277 97.162l97.018-97.018 926.837 926.837-97.018 97.018-926.837-926.837z" fill="#ffffff")/
  div.modal-title.bg.red.center.padding._10(v-if="title") {{title}}
  div.modal-content
    slot/
</template>
<script>
export default {
  props:{
    title: String,
    bgcolor: {
      type: String,
      default: '#FFDF7D'
    },
    height: {
      type: String,
      default: '60vh'
    },
    width: {
      type: String,
      default: '80%'
    },
    closeIco: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    _close(){
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
.__shadow(@n){
  @shadow: 0.0625rem * @n;
  box-shadow: 0 0 @shadow rgba(0,0,0,.4);
}
.modal-box{
  &.hide{display: none;}
  .__shadow(10);
  .ico-close{
    .__position(2, -1, -12, -12);
    border-radius: 50%;
    box-sizing: border-box;
    .__shadow(10);
    .__wh(24, 24);
    .__bg(#FFBA00);
    svg{
      .__wh(12, 12);
      .__position(2, 6, 6);
    }
  }
}
</style>
